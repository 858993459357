// error-types.ts
export enum ErrorCode {
  // User-facing
  /**
   * The name should be meaningful enough
   */
  VALIDATION_ERROR = 1001,
  AUTHENTICATION_FAILED = 2001,
  QUOTE_EXCEEDED_ERROR = 2002,
  ACCOUNT_PACKAGE_EXPIRATION_ERROR = 2003,
  RECAPTCHA_ERROR = 3003,
  EXTERNAL_API_ERROR = 4001,

  // System
  INTERNAL_SERVER_ERROR = 5000,
}
/**
 * Default messages based on error types
 */
export const ErrorDefaultMessages: Record<ErrorCode, string> = {
  [ErrorCode.VALIDATION_ERROR]: 'Invalid input data',
  [ErrorCode.AUTHENTICATION_FAILED]: 'Authentication failed',
  [ErrorCode.INTERNAL_SERVER_ERROR]:
    'Unexpected error. Please, try again later.',
  [ErrorCode.QUOTE_EXCEEDED_ERROR]: 'Exceeded account quota limit.',
  [ErrorCode.ACCOUNT_PACKAGE_EXPIRATION_ERROR]: 'Account Package has expired.',
  [ErrorCode.RECAPTCHA_ERROR]: 'Please refresh the page and try again.',
  [ErrorCode.EXTERNAL_API_ERROR]:
    'Error Retrieving your infromation from the external API.', //this message should be always overriden
};

export class ErrorResponse {
  code: ErrorCode;
  status?: number;
  message: string;
  details?: any;
}
