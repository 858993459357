import { Injectable } from '@angular/core';
import { ErrorSnackbarService } from './error-snackbar.service';
import { ErrorCode } from '@codenteam/core/errors';

export interface ErrorObject {
  message: string;
  errorCode: ErrorCode;
}
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private overrideHandlers: Map<string, (error: ErrorObject) => void> =
    new Map();

  private overrideHandler: (error: ErrorObject) => void = undefined;

  private activeErrors = new Set<ErrorCode>();
  whenSnackbarCloses = (errorCode: ErrorCode) => {
    this.activeErrors.delete(errorCode);
  };
  constructor(private errorSnackbarService: ErrorSnackbarService) {
    this.errorSnackbarService.attachFunctionWhenBarSnackCloses(
      this.whenSnackbarCloses
    );
  }

  showError(error: ErrorObject) {
    /**
     * Override handler for all types of errors
     */
    if (this.overrideHandler) {
      this.overrideHandler!(error);
      return;
    }

    /**
     * Override handler for specific Error Code
     */

    if (this.overrideHandlers.has(error.errorCode.toString())) {
      this.overrideHandlers.get(error.errorCode.toString())!(error);
      return;
    }

    if (this.activeErrors.has(error.errorCode)) return;

    this.activeErrors.add(error.errorCode);
    this.errorSnackbarService.showError(error.message, error.errorCode);
  }
  /**
   *
   * Override default error handler for certain error code
   * @param errorCode Code to handle
   * @param handler Handler function
   */
  overrideErrorByErrorCode(
    errorCode: string,
    handler: (error: ErrorObject) => void
  ) {
    this.overrideHandlers.set(errorCode, handler);
  }

  /**
   * Override default error handler for all errors
   * @param handler Handler function
   */
  overrideError(handler: (error: ErrorObject) => void) {
    this.overrideHandler = handler;
  }
  /**
   * Remove handler by error code (if no other handlers, the service will revert to the default handler)
   * @param errorCode Code to handle
   */
  removeOverrideByErrorCode(errorCode: string) {
    this.overrideHandlers.delete(errorCode);
  }

  /**
   * Remove Override error handler (if no other handlers, the service will revert to the default handler)
   */
  removeOverrideError() {
    this.overrideHandler = undefined;
  }
}
